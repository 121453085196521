import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";

import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

import sessionRoutes from "./views/sessions/session-routes";
import materialRoutes from "app/views/material-kit/MaterialRoutes";

// E-CHART PAGE
const AppEchart = Loadable(lazy(() => import("app/views/charts/echarts/AppEchart")));
// DASHBOARD PAGE
const Landing = Loadable(lazy(() => import("app/views/dashboard/Landing")));

const SocialLogins = Loadable(lazy(() => import("app/views/social-logins/SocialLogins")));
const AutoPilot = Loadable(lazy(() => import("app/views/auto-pilot/AutoPilot")));
const CreatePost = Loadable(lazy(() => import("app/views/create-post/CreatePost")));
const TextToPost = Loadable(lazy(() => import("app/views/texttopost/TextToPost")));
const PhotoUpload = Loadable(lazy(() => import("app/views/photo-upload/PhotoUpload")));
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));

const routes = [
  {
    path: "/",
    element: <Navigate to="dashboard/default" />
  },
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      {
        path: "/dashboard/default",
        element: <Landing />      },
      // e-chart route
      {
        path: "/charts/echarts",
        element: <AppEchart />
      },
      // Social Logins route
      {
        path: "/social-media-logins",
        element: <SocialLogins />
      },
      // Auto Pilot route
      {
        path: "/social-media-manager",
        element: <AutoPilot />
      },
      // File Upload route
      {
        path: "/photo-upload",
        element: <PhotoUpload />
      },
      // Create A Post route
      {
        path: "/create-post",
        element: <CreatePost />
      },
      // SMS Text Consent
      {
        path: "/text-to-post",
        element: <TextToPost />
      },
      // SMS Text Consent
      {
        path: "/analytics",
        element: <Analytics />
      }
    ]
  },

  // session pages route
  ...sessionRoutes
];

export default routes;
