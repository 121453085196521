import { createContext, useEffect, useReducer } from "react";
import axios from "axios";
// CUSTOM COMPONENT
import { MatxLoading } from "app/components";

const initialState = {
  user: null,
  token: null,
  isInitialized: false,
  isAuthenticated: false,
  ...JSON.parse(localStorage.getItem('state') || '{}'),
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user, token } = state;
      return { ...state, isAuthenticated, isInitialized: true, user, token };
    }

    case "LOGIN": {
      const { user, token } = action.payload;
      localStorage.setItem('state', JSON.stringify({ isAuthenticated: true, user, token }));
      return { ...state, isAuthenticated: true, user: action.payload.user, token: action.payload.token};
    }

    case "LOGOUT": {
      localStorage.removeItem('state');
      return { ...state, isAuthenticated: false, user: null, token: null};
    }

    case "REGISTER": {
      const { user,token } = action.payload;

      return { ...state, isAuthenticated: true, user, token };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => {},
  logout: () => {},
  register: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login/`, { email, password });
      const { user, token } = response.data;
      dispatch({ type: "LOGIN", payload: { user, token } });
    } catch (error) {
      console.log("Invalid credentials!")
      console.error('Error: ', error);
    }
    //dispatch({ type: "LOGIN", payload: { user } });
  };

  const register = async (email, username, businessName, password) => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/register/`, { 'email':email, 'username':email, businessName, password, timeZone, 'fullName':username });
    const { user } = response.data;
    console.log("Response: ", response);

    dispatch({ type: "REGISTER", payload: { user } });
  };

  const logout = () => {
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    (async () => {
      try {
        // const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/profile/`);
        const localState = localStorage.getItem('state');
        dispatch({ type: "INIT", payload: { isAuthenticated: true, user: localState.user, token: localState.token } });
      } catch (err) {
        console.error(err);
        dispatch({ type: "INIT", payload: { isAuthenticated: false, user: null } });
      }
    })();
  }, []);

  // SHOW LOADER
  if (!state.isInitialized) return <MatxLoading />;

  return (
    <AuthContext.Provider value={{ ...state, method: "JWT", login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
