import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";
import App from "./app/App";
import mixpanel from "mixpanel-browser";

// third party style
import "perfect-scrollbar/css/perfect-scrollbar.css";

if (process.env.REACT_APP_ENV === "development") {
  // const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN_DEV;
  const mixpanelToken = "82b2e72dc85a836a31e9287164c4b3b9";
  console.log("REACT_APP_ENV:", process.env.REACT_APP_ENV);
  console.log("Mixpanel token DEV:", mixpanelToken);
  mixpanel.init(mixpanelToken, {
    track_pageview: true,
    persistence: "localStorage",
    debug: true,
    ignore_dnt: true,
  });
  
} else {
  //const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN_PROD;
  const mixpanelToken = "10c45ea1a01fcf81eb3271c16154f171";
  // console.log("Mixpanel token PROD:", mixpanelToken);
  mixpanel.init(mixpanelToken, {
    track_pageview: true,
    persistence: "localStorage",
  });
}

const root = createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
